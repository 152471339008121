import { createServiceManifest } from '@estee/elc-service';
import { OLAPIC_WIDGET } from '@estee/elc-service-view-names';
import { IManifestServiceRegistryComp } from '@estee/elc-universal-utils';
import { ProductUgcService } from './service-setup/ProductUgcService';

const { name, version } = __serviceInfo__;

const serviceViews = [OLAPIC_WIDGET];
const serviceEvents: string[] = [];
const serviceQueries: string[] = [];

export const ServiceManifest: IManifestServiceRegistryComp = createServiceManifest<
    ProductUgcService
>(
    {
        views: serviceViews,
        events: serviceEvents,
        queries: serviceQueries,
        name,
        version
    },
    () =>
        import(
            /* webpackChunkName: 'core' */
            './service-setup/diContainer'
        ),
    'productUgcService'
);
