(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("HypoContainer"), require("mobx"), require("ElcServiceBus"), require("react"), require("mobx-react"), require("styled-components"));
	else if(typeof define === 'function' && define.amd)
		define(["HypoContainer", "mobx", "ElcServiceBus", "react", "mobx-react", "styled-components"], factory);
	else if(typeof exports === 'object')
		exports["elc-service-product-ugc"] = factory(require("HypoContainer"), require("mobx"), require("ElcServiceBus"), require("react"), require("mobx-react"), require("styled-components"));
	else
		root["elc-service-product-ugc"] = factory(root["ElcRuntime"]["HypoContainer"], root["ElcRuntime"]["mobx"], root["ElcServiceBus"], root["ElcRuntime"]["React"], root["ElcRuntime"]["mobxReact"], root["ElcRuntime"]["styled5"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE_hypo_container__, __WEBPACK_EXTERNAL_MODULE_mobx__, __WEBPACK_EXTERNAL_MODULE__estee_elc_service_bus__, __WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_mobx_react__, __WEBPACK_EXTERNAL_MODULE_styled_components__) {
return 